import React, { useMemo, useState } from "react";
import { CellProps } from "./Cell";
import { BASE_COLUMN_TYPES, ColumnDataMap, DeletedOrder, OrderType } from "../config/types";
import { Button, CheckmarkIcon, Csku, Dropdown, IconButton, Typography } from "@commonsku/styles";
import { restoreJob, restoreOrder } from "../../../../redux/clientDetails";
import { getImageSrc, titleCase } from "../../../../utils";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useReportContext } from "../../report_context";

const ITEM_IMAGE_STYLE = {
    maxWidth: 45,
    maxHeight: 45,
};

const ProjectCellContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const ItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

const RestoreOrderContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

const orderTypeSortOrder: Record<OrderType, number> = {
    OPPORTUNITY: 0,
    PRESENTATION: 1,
    ESTIMATE: 2,
    "SALES ORDER": 3,
    INVOICE: 4,
} as const;

type OrderGroups = {
    [orderType in OrderType]: DeletedOrder[];   
};

type TColumn = typeof BASE_COLUMN_TYPES.Project;
const ProjectCell = ({
    column,
    row,
    className,
    onClick,
    style = {},
}: CellProps<TColumn>) => {
    const { fetchReport } = useReportContext();
    const { accessorKey, dataTransform } = column;
    const { job_id, job_name, job_number, items, job_active, deleted_orders } = useMemo<ColumnDataMap<TColumn>>(
        () => ({ ...row.original[accessorKey] }),
        [accessorKey, dataTransform, row.original],
    );
    const jobLabel = `#${job_number}: ${job_name}`;

    const dispatch = useDispatch();
    const [restored, setRestored] = useState(false);
    const href = `/project/${job_number}`;
    const itemsToShow = items.slice(0, 5);
    const showHref = job_active || restored;

    const handleRestoreJob = () => {
        dispatch(restoreJob(job_id));
        setRestored(true);
    }

    const handleRestoreOrder = (order_id) => {
        dispatch(restoreOrder(order_id));
        fetchReport();
    }

    const deletedOrderGroups = (deleted_orders ?? [])
        .filter(order => order.order_type in orderTypeSortOrder)
        .reduce((groups: Partial<OrderGroups>, order) => {
            const orderType = order.order_type;
            
            if (!groups[orderType]) {
                groups[orderType] = [];
            }

            groups[orderType].push(order);
            return groups;
        }, {});

    const deletedOrderOptions = Object.entries(deletedOrderGroups)
        .sort(([a], [b]) => orderTypeSortOrder[a] - orderTypeSortOrder[b]);

    return (
        <Csku className={className} onClick={onClick}>
            <ProjectCellContainer>
                <Typography.Span
                    as={showHref ? "a" : "span"}
                    style={{
                        display: "block",
                        alignContent: "left",
                        color: showHref ? "var(--color-primary1-main)" : "unset",
                    }}
                    href={showHref ? href : undefined}
                    target="_blank"
                    rel="noreferrer"
                >
                    {jobLabel}
                </Typography.Span>
                {(job_active && deletedOrderOptions.length > 0) ? (
                    <RestoreOrderContainer>
                        {deletedOrderOptions.map(([orderType, orders]) => (
                            <Dropdown
                                key={orderType}
                                text={`Restore ${titleCase(orderType)}`}
                                bordered
                                size="small"
                                buttonVariant="primary"
                                items={orders.map(order => ({
                                    content: `${titleCase(orderType)} #${order.form_number}`,
                                    onClick: () => handleRestoreOrder(order.order_id),
                                }))}
                            />
                        ))}
                    </RestoreOrderContainer>
                ) : (
                    <ItemContainer>
                        {itemsToShow.map((item) => (
                            <img
                                key={item.item_id}
                                data-for={"report-table-tooltip"}
                                data-tip={item.item_name}
                                alt={item.item_name}
                                src={getImageSrc(item)}
                                style={ITEM_IMAGE_STYLE}
                            />
                        ))}
                    </ItemContainer>
                )}
                {!job_active && (!restored ? (
                    <div>
                        <Button
                            size="small"
                            onClick={handleRestoreJob}
                        >
                            Restore
                        </Button>
                    </div>
                ) : (
                    <div>
                        <IconButton
                            size="small"
                            Icon={CheckmarkIcon}
                            iconPosition="left"
                            disabled
                        >
                            Restored
                        </IconButton>
                    </div>
                ))}
            </ProjectCellContainer>
        </Csku>
    );
};

export default ProjectCell;
